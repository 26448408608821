import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import CardMission from "./CardMission";

const lstMissions = [
    {
        index: '1',
        title: 'Tận tâm',
        contents: [
            "Chu đáo",
            "Tận tâm phục vụ",
            "Luôn sẵn sàng hỗ trợ khách hàng 24/7",
        ]
    },
    {
        index: '2',
        title: 'Uy tín',
        contents: [
            "ANGEL & BEAUTY luôn lấy chữ Tín làm đầu, cam kết mang đến cho khách hàng chất lượng sản phẩm tốt nhất cùng chi phí hợp lý",
        ]
    },
    {
        index: '3',
        title: 'Hoàn Mỹ',
        contents: [
            "Mỗi sản phẩm của ANGEL & BEAUTY đều được chau chuốt để đạt đến vẻ đẹp hoàn mỹ nhất",
        ]
    }
]

export default function ContentMission() {
    return (
        <Grid container sx={{
            padding: "32px 16px",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                maxWidth: "80%"
            }}>
                <Grid item xs={12}>

                    <Typography
                        variant='h4'
                        m={4}
                        textAlign={'center'}
                        textTransform={'uppercase'}
                    >
                        VỀ CHÚNG TÔI
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            margin: "20px 50px",
                        }}
                        textAlign={'center'}
                    >
                        Với cái tâm và tầm, ANGEL & BEAUTY ra đời với sứ mệnh “Khơi nguồn nhan sắc”, tôn vinh và nâng tầm sắc đẹp, chinh phục những giá trị hoàn mỹ mà con người ta hằng hướng đến.
                    </Typography>
                    <Typography
                        variant='h4'
                        m={4}
                        textAlign={'center'}
                        textTransform={'uppercase'}
                    >
                        GIÁ TRỊ CỐT LỖI
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            margin: "20px 50px",
                        }}
                        textAlign={'center'}
                    >
                        ANGEL & BEAUTY luôn lấy giá trị cốt lõi TÂM – TÍN – MỸ làm kim chỉ nam cho mọi hoạt động, từng bước đưa ANGEL & BEAUTY trở thành đơn vị cung cấp mỹ phẩm chăm sóc da uy tín, chất lượng hàng đầu tại Việt Nam
                    </Typography>
                    <Grid container sx={{
                        margin: "10px 10px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                    }}>
                        {lstMissions.map((mission: any) => (
                            <Grid item xs={3} sx={{
                                margin: "0 10px"
                            }}>
                                <CardMission mission={mission} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}