import { Box, Grid } from '@mui/material';
import React from 'react';
import ContentFeedback from './Feedback/Feedback';
import Footer from './Footer';
import Header from './Header';
import Missions from './Mission/Missions';
import ContentProduct from './Product/Product';
import SliderProduct from './Product/SliderProduct';
import SliderBanner from './SliderBanner';

function App() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <SliderBanner />
        <Missions />
        <ContentProduct />
        <ContentFeedback />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default App;
