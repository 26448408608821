import { Box, Grid, Typography } from "@mui/material";
import SliderFeedback from "./SlideFeedback";

export default function ContentFeedback() {
    return (
        <Grid container sx={{
            padding: "32px 16px",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                maxWidth: "80%"
            }}>
                <Grid item xs={12}>
                    <Typography
                        variant='h4'
                        m={4}
                        textAlign={'center'}
                        textTransform={'uppercase'}
                    >
                        Phản hồi từ khách hàng
                    </Typography>
                    <SliderFeedback />
                </Grid>
            </Box>
        </Grid>
    )
}