import 'swiper/css';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CardProductPerlume from "./CardProductPerfume";

const lstProducts = [
    {
        img: "Images/Product/Perfume/Because_Of_Love.jpeg",
        title: "Nước hoa Because Of Love",
        describe: "Chai nước hoa Because Of Love được thiết kế sang trọng với hương thơm và màu đen huyền bí, thích hợp sử dụng trong các dịp quan trọng hoặc làm quà tặng cho người thân, bạn nè hay người yêu.",
        price: "679.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Nam tính, mạnh mẽ, tinh tế",
        capacity: "50ml",
        features: [
            "Hương đầu: Quả dứa, Cam Bergamot, Quả lý chua đen, Táo",
            "Hương giữa: Bạch dương, Cây hoắc hương, Hoa nhài Maroc, Hoa hồng",
            "Hương cuối: Xạ hương, rêu sồi, Long diên hương, Vanille",
        ]
    },
    {
        img: "Images/Product/Perfume/Paris_Love_Affair.jpeg",
        title: "Nước hoa Paris Love Affair",
        describe: "Paris Love Affair là một loại nước hoa nhẹ nhàng thơm lâu cho nữ nhưng không quá ngọt gắt, phù hợp để sử dụng cho cả ngày và đêm",
        price: "639.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Ngọt ngào, nữ tính, nhẹ nhàng",
        capacity: "50ml",
        features: [
            "Hương đầu: Iris",
            "Hương giữa: Cây hoắc hương",
            "Hương cuối: Hương vị ngọt ngào của đường và vani ấm áp",
        ]
    },
    {
        img: "Images/Product/Perfume/Wish_504.jpeg",
        title: "Nước hoa Wish 504",
        describe: "Nước hoa Wish 504 cực kỳ thích hợp cho cả khi dùng hàng ngày hay trong những dịp đặc biệt, giúp tạo nên dấu ấn riêng biệt và khiến bạn trở nên cuốn hút hơn trong mọi tình huống",
        price: "679.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Quyến rũ, cuốn hút, gợi cảm",
        capacity: "50ml",
        features: [
            "Hương đầu: Quả Yuzu, Hoa cam Neroli",
            "Hương giữa: Cây hương thảo, Cây húng tây, Cát, Đinh hương, Hạt tiêu hồng",
            "Hương cuối: Cây bách"
        ]
    },
    {
        img: "Images/Product/Perfume/Just_Need_Each_Other_13.jpeg",
        title: "Nước hoa Just Need Each Other 13",
        describe: "Nếu yêu thích sự quý phái, tinh tế và lãng mạn, muốn tạo ấn tượng đặc biệt trong mỗi cuộc gặp gỡ và những sự kiện quan trọng, nước hoa Just Need Each Other 13 sẽ là sự lựa chọn hoàn hảo cho bạn",
        price: "679.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Nồng nàn, gợi cảm, tinh tế",
        capacity: "50ml",
        features: [
            "Hương đầu: Quả lê, Táo và Cam quýt",
            "Hương giữa: Ambrette (Xạ hương), Amyl Salicylate, Rêu và Hoa nhài",
            "Hương cuối: Iso E Super, Cetalox, Ambrettolide và Helvetolide",
        ]
    },
    {
        img: "Images/Product/Perfume/If_You_Is_Not_A_Dream.jpeg",
        title: "Nước hoa If You Is Not A Dream",
        describe: "Nước hoa If You Is Not A Dream được đánh giá là loại nước hoa nữ được ưa chuộng và yêu thích bởi đông đảo những người phụ nữ hiện đại, quý phái và có gu thẩm mỹ cao",
        price: "679.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Sang trọng, quyến rũ, hấp dẫn",
        capacity: "50ml",
        features: [
            "Hương đầu: Quýt, Petitgrain Citronnier, Cam Bigarade, Lý chua đen, Hoa oải hương, Hạt tiêu hồng",
            "Hương giữa: Hoa hồng Bungari, Hoa linh lan thung lũng",
            "Hương cuối: Xạ hương, Balsamic, Vani, Benzoin, Long diên hương",
        ]
    },
    {
        img: "Images/Product/Perfume/Our_Lips_Touched.jpeg",
        title: "Nước hoa Our Lips Touched",
        describe: "Nước hoa nam Our Lips Touched sở hữu hương thơm vượt trội, là một trong những lựa chọn hoàn hảo cho những Quý ông hiện đại. Mỗi tầng hương đều thể hiện sự tự tin và đẳng cấp của người sử dụng",
        price: "639.000 đồng",
        trademark: "Story Of Paris",
        origin: "Việt Nam",
        style: "Sang trọng, cuốn hút, lịch lãm",
        capacity: "50ml",
        features: [
            "Hương đầu: Cam bergamot, Chanh, Quýt, Bưởi, Hạt tiêu, Táo",
            "Hương giữa: Nhục đậu khấu, Bạch đậu khấu, Rễ hoa diên vĩ, Hoa violet",
            "Hương cuối: Gỗ đàn hương, Hoắc hương, Cỏ hương bài, Hổ phách, Xạ hương",
        ]
    }
]

export default function SliderProductPerfume() {
    return (
        <>
            <Typography
                variant='h5'
                paddingLeft={"20px"}
                textTransform={'uppercase'}
                marginTop={5}
                marginBottom={2}
                borderBottom={"1px solid black"}
            // m={2}
            >
                <b>NƯỚC HOA</b>
            </Typography>
            <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                spaceBetween={15}
                slidesPerView={3}
                loop={true}
                // navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 2000 }}
            >
                {lstProducts.map((product) => (
                    <SwiperSlide>
                        <CardProductPerlume product={product} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}