import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Box, Fab, Grid, Link, Typography } from "@mui/material";

export default function Footer() {
    return (
        <Grid container sx={{
            backgroundColor: "#fef84c66"
        }}>
            <Grid item xs={12} sx={{
                marginTop: 10,
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={3} sx={{
                        textAlign: "center"
                    }}>
                        <img src="Images/Logo/logo.png" alt="" width={240} height={240}></img>
                    </Grid>
                    <Grid item xs={6} sx={{
                        padding: "10px 25px"
                    }}>
                        <Typography variant='h5' sx={{
                            textTransform: "uppercase"
                        }}>
                            Thông tin liên hệ
                        </Typography>
                        <Typography>
                            <ul>
                                <li><b>Số điện thoại: </b>
                                    <ul>
                                        <li><i>0943795239</i></li>
                                        <li><i>0785038039</i></li>
                                    </ul>
                                </li>
                                <li><b>Email: </b> <i>angelbeautyvietnam2022@gmail.com</i></li>
                                <li><b>Mã số thuế: </b> <i>3703098298</i></li>
                                <li><b>Địa chỉ: </b> <i>Shophouse 58, block D, khu căn hộ Bcons Garden, số 25A Phạm Hữu Lầu,
                                    khu phố Thống Nhất 1, Phường Dĩ An, Thành phố Dĩ An, Tỉnh Bình Dương, Việt Nam</i></li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='h5' sx={{
                            textTransform: "uppercase"
                        }}>
                            Theo dõi chúng tôi
                        </Typography>
                        <Box sx={{
                            '& > :not(style)': { m: 1 },
                            marginTop: "10px",
                            marginLeft: "20px"
                        }}>
                            <Fab color="primary" aria-label="facebook" sx={{
                                backgroundColor: "#ffff",
                                borderRadius: "50%"
                            }}>
                                <Link href="https://www.facebook.com/profile.php?id=100088670806013" target="_blank" width={"64px"} height={"64px"}>
                                    <img src='Images/Logo/facebook.png' width={"64px"} height={"64px"} />
                                </Link>
                            </Fab>
                            <Fab color="primary" aria-label="instagram" sx={{
                                backgroundColor: "#ffff",
                                borderRadius: "50%"
                            }}>
                                <Link href="https://www.facebook.com/profile.php?id=100088670806013" target="_blank" width={"64px"} height={"64px"}>
                                    <img src='Images/Logo/instagram.png' width={"64px"} height={"64px"} />
                                </Link>
                            </Fab>
                            <Fab color="primary" aria-label="youtube" sx={{
                                backgroundColor: "#ffff",
                                borderRadius: "50%"
                            }}>
                                <Link href="https://www.facebook.com/profile.php?id=100088670806013" target="_blank" width={"64px"} height={"64px"}>
                                    <img src='Images/Logo/youtube.png' width={"64px"} height={"64px"} />
                                </Link>
                            </Fab>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "#fef84ccc" }}>
                <Box
                    padding={"20px 0"}
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <Typography variant='h6' fontWeight={"bold"} sx={{
                        textTransform: 'uppercase',
                    }}>
                        CÔNG TY TNHH THƯƠNG MẠI ANGEL BEAUTY VIỆT NAM
                    </Typography>
                    <Typography>
                        Giấy chứng nhận đăng ký doanh nghiệp - do Sở kế hoạch và đầu tư tỉnh Bình Dương cấp ngày 14 tháng 12 năm 2022
                    </Typography>
                    <Typography sx={{
                        marginTop: "5px"
                    }}>
                        Copyright 2023 © {" "}
                        <Link href="#" sx={{
                            textDecoration: "none",
                            color: "#000",
                            fontWeight: "bold",
                        }}>Angelbeautyvietnam.vn</Link>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}   