import React from "react";
import 'swiper/css';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CardProduct from "./CardProduct";

const lstProducts = [
    {
        img: "Images/Product/skin_power_serum.jpeg",
        title: "SKIN POWER SERUM",
        describe: "Tinh chất đặc trị dưỡng da trắng sáng, se khít lỗ chân lông (30g)",
        uses: [
            "Dưỡng trắng da.",
            "Dưỡng ẩm da.",
            "Làm mờ vết thâm nám, tàn nhang.",
            "Giúp da đều màu.",
            "Phục hồi da.",
            "Ngăn ngừa lão hóa da."
        ],
        elements: [
            "10% Niacinamide.",
            "Allantoin.",
            "Beta Glucan.",
            "Palmitoyl Hexapeptide-14.",
            "Dầu Argan, dầu hạt Mac-ca.",
            "Chiết xuất kỷ tử.",
            "Vitamin E, B5,..."
        ],
        price: "489.000 đồng"
    },
    {
        img: "Images/Product/whitening_and_perfect.jpeg",
        title: "WHITENING AND PERFECT",
        describe: "Kem dưỡng trắng da (50g)",
        uses: [
            "Dưỡng trắng da.",
            "Làm mờ vết thâm nám, tàn nhang.",
            "Giúp da đều màu.",
            "Phục hồi da, giúp da mềm mịn.",
            "Ngăn ngừa lão hóa da."
        ],
        elements: [
            "Chiết xuất 12 loài hoa (Kim ngân hoa, Hoa mộc lan, Hoa sen, Hoa mận mơ, Hoa Anh đào, Cúc đại đóa , Cúc la mã, Hoa trà mi, Hồng hoa, Hoa cúc vàng, Hoa bách hợp) và 5 loại thảo dược (Ngải đắng, Rau sam, Phong đường, Kỷ từ, Rễ long đởm) quý hiếm.",
            "Bioplacenta.",
            "Bột kim cương.",
            "Sericin.",
            "Melatonin.",
            "Beta Glucan.",
            "Tảo biển,..."
        ],
        price: "479.000 đồng"
    },
    {
        img: "Images/Product/WHITENING_SKINCARE_CREAM_7_IN_1.jpeg",
        title: "WHITENING SKINCARE CREAM 7 IN 1",
        describe: "Kem dưỡng trắng da ngọc trai (20g)",
        uses: [
            "Dưỡng trắng da",
            "Dưỡng ẩm da",
            "Làm mờ vết thâm nám và tàn nhang",
            "Ngăn ngừa mụn",
            "Chống nắng hiệu quả",
            "Phục hồi da",
            "Ngăn ngừa lão hóa da",
        ],
        elements: [
            "Tocopherol",
            "Kojic Acid Dipalmitate",
            "Hydrolyzed Pearl",
            "Lactobacillus Ferment",
            "Inonotus Obliquus (Mushroom) Extract",
            "Đông Trùng Hạ Thảo",
            "Ganoderma Lucidum (Mushroom) Stem Extract",
            "PHA (AHA thế hệ thứ 2)",
        ],
        price: "449.000 đồng"
    },
    {
        img: "Images/Product/WHITENING_SKINCARE_CREAM_9_IN_1.jpeg",
        title: "WHITENING SKINCARE CREAM 9 IN 1",
        describe: "Kem dưỡng trắng da linh chi (20g)",
        uses: [
            "Dưỡng trắng da",
            "Dưỡng ẩm da",
            "Làm mờ vết thâm nám và tàn nhang",
            "Giúp da đều màu",
            "Ngăn ngừa mụn",
            "Chống nắng hiệu quả",
            "Phục hồi da, giúp da mềm mịn",
            "Ngăn ngừa lão hóa da",
        ],
        elements: [
            "Kojic Acid Dipalmitate.",
            "Palmitoyl Hexapeptide-12.",
            "Sodium hyaluronate.",
            "PHA (AHA thế hệ thứ 2).",
            "Gluconolactone.",
            "Lactobacillus Ferment.",
            "Inonotus Obliquus (Mushroom) Extract.",
            "Ganoderma Lucidum (Mushroom) Stem Extract.",
            "Đông Trùng Hạ Thảo.",
            "Bột ngọc trai.",
            "Dầu hạt nho, dầu cám gạo, dầu quả bơ.",
        ],
        price: "479.000 đồng"
    },
    // {
    //     img: "Images/Product/.jpeg",
    //     title: "CONCENTRATE WHITENING DAY",
    //     describe: "Kem Face dưỡng ngày (50g)",
    //     uses: [
    //         "Giúp dưỡng trắng da, và trị thâm nám",
    //         "Cải thiện những vùng da sạm màu",
    //         "Dưỡng ẩm da, làm mờ nếp nhăn.",
    //         "Bảo vệ lớp màng ceramivde, tăng khả năng miễn dịch",
    //         "Chống nắng.",
    //         "Da mịn màng, trắng sắng",
    //     ],
    //     elements: [
    //         "Multi Peptide",
    //         "Vitamin B9, Acetylated Glutamine",
    //         "Tocopherol",
    //         "Butyrospermum Parkii Butter",
    //         "Allantoin",
    //         "Sodium Hyaluronate",
    //         "Macadamia Integrifolia Seed Oli",
    //         "Argania Spinosa Kernel Oil",
    //         "Chiết xuất yến mạch",
    //         "Chiết xuất nhân sâm",
    //     ],
    //     price: "Giá: 389.000 đồng (chưa bao gồm chiết khấu)."
    // },
    {
        img: "Images/Product/SUN_SHELTER_SPF.jpeg",
        title: "SUN SHELTER SPF 50+/PA++++",
        describe: "Kem chống nắng nâng tone màu da (30g)",
        uses: [
            "Chống nắng phổ rộng giúp bảo vệ da khỏi tác hại của ánh nắng mặt trời, khói bụi",
            "Có khả năng make up giúp đều màu da tự nhiên",
            "Bổ sung nhiều dưỡng chất giúp dưỡng ẩm và mịn màng",
            "Giúp làm mờ vết thâm nám và dưỡng trắng da",
            "Giúp ngăn ngừa lão hóa da",
        ],
        elements: [
            "Titanium Dioxide",
            "Zinc Oxide",
            "Ethylhexyl Methoxycinnamate",
            "Carthamus Tinctorius Flower Extract",
            "Camellia Japonice Flower Extract",
        ],
        price: "279.000 đồng"
    },
    {
        img: "Images/Product/GENAISSANCE_FOAM.jpeg",
        title: "GENAISSANCE FOAM",
        describe: "Sữa rửa mặt sạch sâu (120ml)",
        uses: [
            "Làm sạch bụi bẩn, dầu thừa và bã nhờn trên da.",
            "Dưỡng ẩm da, giúp da mềm mịn",
            "Ngăn ngừa mụn",
            "Không bào mòn và không làm khô da",
        ],
        elements: [
            "Axit Lactic",
            "Allantoin",
            "Panthenol",
            "Niacinamide",
            "Quaternium-73",
            "Citric Acid",
            "Aloe Barbadensis Leaf Extract",
            "Melaleuca Alternifolia Leaf Oil",
        ],
        price: "229.000 đồng"
    },
    {
        img: "Images/Product/GENAISSANCE_INFUSED.jpeg",
        title: "GENAISSANCE INFUSED",
        describe: "Nước thần dưỡng trắng da, se khít lô chân lông (120ml)",
        uses: [
            "Cải thiện kết cấu da, giúp da căng bóng, săn chắc hơn",
            "Hỗ trợ chống lão hóa, làm tăng độ sáng bóng của làn da, giúp da mịn màng",
        ],
        elements: [
            "Allantoin",
            "Sodium Hyaluronate",
            "Dịch lên men Galactomyces",
            "Multi Peptide",
            "Chiết xuất tảo biển",
            "Chất nhầy ốc sên",
        ],
        price: "349.000 đồng"
    },
    {
        img: "Images/Product/SMOOTHING_SHOWER_COCO_PERFUME.jpeg",
        title: "SMOOTHING SHOWER COCO PERFUME",
        describe: "Sữa tắm dưỡng trắng hương nước hoa Pháp (200g)",
        uses: [
            "Làm sạch bụi bẩn, bã nhờn trên da",
            "Dưỡng ẩm da, giúp da mềm mịn.",
            "Mang lại cảm giác thư giãn thoải mái sau khi tắm",
        ],
        elements: [
            "Niacinamide",
            "Chiết xuất lựu",
            "Chiết xuất lá rau má",
            "Chiết xuất cam thảo",
            "Chiết xuất hắc mai biển",
            "Chiết xuất hoa hồng Damas",
        ],
        price: "179.000 đồng"
    },
    {
        img: "Images/Product/WHITENING_TONE_UP_BODY_LOTION_PERFUME.jpeg",
        title: "WHITENING TONE UP – BODY LOTION PERFUME",
        describe: "Dưỡng thể nâng tone, trắng da, hương nước hoa Pháp (200g)",
        uses: [
            "Dưỡng trắng da, giúp da đều màu.",
            "Cấp ẩm và dưỡng ẩm da.",
            "Chống nắng hiệu quả",
            "Ngăn ngừa lão hóa da, giúp da mịn màng rạng rỡ.",
            "Kết cấu kem body mỏng mịn thấm nhanh, make up nâng tone nhẹ nhàng cho giúp làn da đều màu, tự nhiên cùng với hương thơm dễ chịu, thư giãn."
        ],
        elements: [
            "Niacinamide",
            "Alpha Arbutin",
            "Hydrolyzed Collagen",
            "Magnolia Extract",
            "Gluconolactone.",
            "Dầu hạt nho",
            "Chiết xuất hoa Lonicera Japonica",
            "Chiết xuất hoa Cúc mâm xôi",
            "Chiết xuất hoa Mơ",
            "Chiết xuất hoa Anh đào Nhật Bản",
            "Chiết xuất hoa Cúc Vàng",
        ],
        price: "229.000 đồng"
    },
    // {
    //     img: "Images/Product/.jpeg",
    //     title: "",
    //     describe: "",
    //     uses: [
    //         ""
    //     ],
    //     elements: [
    //         ""
    //     ],
    //     price: "Giá: .000 đồng (chưa bao gồm chiết khấu)."
    // },
]

function SliderProduct() {
    return (
        <>
            <Typography
                variant='h5'
                paddingLeft={"20px"}
                textTransform={'uppercase'}
                marginTop={5}
                marginBottom={2}
                borderBottom={"1px solid black"}
            // m={2}
            >
                <b>MỸ PHẨM</b>
            </Typography>
            <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                spaceBetween={15}
                slidesPerView={3}
                loop={true}
                // navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 2000 }}
            >
                {lstProducts.map((product) => (
                    <SwiperSlide>
                        <CardProduct product={product} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default SliderProduct;