import { Box, CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function CardProduct(props: any) {
    const { product } = props;
    return (
        <Card sx={{
            width: "100%",
            height: "800px",
            borderRadius: 4,
            backgroundColor: "#dae0a2"
        }}>
            {/* <CardActionArea> */}
            <CardContent sx={{
                padding: 0
            }}>
                <Box sx={{
                    position: "relative"
                }}>
                    <CardMedia
                        component="img"
                        alt="Product"
                        height="420"
                        image={product?.img}
                    />
                    <Typography
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px",
                            padding: "8px",
                            borderRadius: "8px",
                            bgcolor: "#fff"
                        }}
                    >{product?.price}</Typography>
                </Box>
            </CardContent>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                    {product?.title}
                </Typography>
                <Box sx={{
                    wordWrap: "break-word",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 10,
                    display: "-webkit-box",
                    overflow: "hidden",
                }}>
                    <Typography variant="body2" marginBottom={"10px"} textAlign={"center"}>
                        {product?.describe}
                    </Typography>

                    <Typography paddingLeft={"40px"}>
                        <Typography variant="body1">
                            <b>Công dụng:</b>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <ul>
                                {product?.uses.map((use: string) => (
                                    <li>{use}</li>
                                ))}
                            </ul>
                        </Typography>
                    </Typography>
                    <Typography paddingLeft={"40px"}>
                        <Typography variant="body1">
                            <b>Thành phần chính:</b>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <ul>
                                {product?.elements.map((element: string) => (
                                    <li>{element}</li>
                                ))}
                            </ul>
                        </Typography>
                    </Typography>
                </Box>
            </CardContent>
            {/* </CardActionArea> */}
            {/* <CardActions sx={{p: 2}}>
                <Button size="small" sx={{background: "#000"}}>Xem chi tiết</Button>
            </CardActions> */}
        </Card>
    )
}