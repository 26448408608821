import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const pages = ['Giới thiệu', 'Sản phẩm', 'Thông tin liên hệ'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header() {
    // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElNav(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    return (
        <AppBar position="static" sx={{
            // bgcolor: "#fef84c66",
            bgcolor: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src="Images/Logo/logo.png" alt='' width={80} height={80} style={{ marginLeft: "64px", marginRight: "16px" }}></img>
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' }
                    }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                // onClick={handleCloseNavMenu}
                                sx={{
                                    my: 2,
                                    display: 'block',
                                    color: "#000",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    marginRight: "16px"
                                }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <IconButton size="large" aria-label="search" sx={{
                        color: "#000"
                    }}>
                        <SearchIcon />
                    </IconButton>
                </Toolbar>
            </Container>
        </AppBar >
    );
}
export default Header;