import React from "react";
import 'swiper/css';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Box, Card, CardContent, CardMedia } from "@mui/material";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const lstImagesFeedBack = [
    "Images/Feedback/Feedback_1.jpeg",
    "Images/Feedback/Feedback_2.jpeg",
    "Images/Feedback/Feedback_3.jpeg",
    "Images/Feedback/Feedback_4.jpeg",
    "Images/Feedback/Feedback_5.jpeg",
    "Images/Feedback/Feedback_6.jpeg",
    "Images/Feedback/Feedback_7.jpeg",
    "Images/Feedback/Feedback_8.jpeg",
    "Images/Feedback/Feedback_9.jpeg",
    "Images/Feedback/Feedback_10.jpeg",
]

function SliderFeedback() {
    return (
        <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={20}
            slidesPerView={2}
            // pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
        >
            {lstImagesFeedBack.map((feedBack) => (
                <SwiperSlide>
                    <Card sx={{
                        width: "100%",
                        borderRadius: 4,
                    }}>
                        <Box sx={{
                            position: "relative"
                        }}></Box>
                        <CardMedia
                            component="img"
                            alt="Product"
                            image={feedBack}
                        />
                    </Card>
                </SwiperSlide>
            ))
            }
        </Swiper >
    )
}

export default SliderFeedback;