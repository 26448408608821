import { Card, Typography } from '@mui/material';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import * as React from 'react';

const theme = createTheme({
    palette: {
        background: {
            paper: '#000',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
        success: {
            dark: '#009688',
        },
    },
});

export default function CardMission(props: any) {
    const { mission } = props;
    return (
        <Card sx={{
            bgcolor: "#fff",
            width: "100%",
            maxHeight: "350px",
            borderRadius: 8,
            boxShadow: "inset 0px 0px 6px 2px rgba(254,248,76,0.3)",
            background: "linear-gradient(transparent 60%, #f3d955)"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "350px",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: "50px",
                    height: "50px",
                    boxShadow: "inset 2px 2px 2px 2px rgba(254,248,76,0.8)",
                    borderRadius: 50,
                    bgcolor: "rgba(254,248,76,0.3)",
                    marginTop: "20px",
                    marginBottom: "30px",
                    fontSize: 24,
                    fontWeight: 'medium',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    {mission?.index}
                </Box>
                <Box sx={{
                    color: "#000",
                    textAlign: 'center',
                    width: "80%",
                    fontSize: '24px',
                    textTransform: 'uppercase',
                    fontWeight: "500",
                    marginBottom: '20px',
                }}>
                    {mission?.title}
                </Box>
                {mission?.contents.map((content: string) => (
                    <Box sx={{
                        textAlign: 'center',
                        lineHeight: '1.6',
                        pl: 4,
                        pr: 4,
                    }}>
                        {content}
                    </Box>
                ))}
            </Box>
        </Card>
    );
}