import React from "react";
import 'swiper/css';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Grid } from "@mui/material";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

function SliderBanner() {
    return (
        <Grid container sx={{
            marginTop: "80px"
        }}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay
            >
                <SwiperSlide>
                    <img src="Images/Banner/Banner_1.png" alt="" style={{ width: "100%" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="Images/Banner/Banner_2.png" alt="" style={{ width: "100%" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="Images/Banner/Banner_3.png" alt="" style={{ width: "100%" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="Images/Banner/Banner_4.png" alt="" style={{ width: "100%" }} />
                </SwiperSlide>
            </Swiper>
        </Grid>
    )
}

export default SliderBanner;