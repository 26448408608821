import { Box, Grid, Typography } from "@mui/material";
import SliderProduct from "./SliderProduct";
import SliderProductPerfume from "./SliderProductPerfume";

export default function ContentProduct() {
    return (
        <Grid container sx={{
            padding: "32px 16px",
            bgcolor: "#9a9c8f",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                maxWidth: "80%"
            }}>
                <Grid item xs={12}>
                    <Typography
                        variant='h4'
                        m={4}
                        textAlign={'center'}
                        textTransform={'uppercase'}
                    >
                        Sản phẩm
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            margin: "20px 50px",
                        }}
                        textAlign={'center'}
                    >
                        Các sản phẩm của ANGEL & BEAUTY được sản xuất trên dây chuyền hiện đại theo công nghệ của Hàn Quốc. Đảm bảo an toàn tuyệt đối, được Sở Y Tế TP. Hồ Chí Minh cấp phép và lưu hành tại thị trường Việt Nam.
                    </Typography>
                    <SliderProduct />
                    <SliderProductPerfume />
                </Grid>
            </Box>
        </Grid >
    )
}